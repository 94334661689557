import * as THREE from 'three';

class ObjectPool {
    constructor(maxSize = 10) {
        this.pool = new Map();
        this.maxSize = maxSize;
        this.activeObjects = new Set();
    }

    // 오브젝트 획득
    acquire(key, createFn) {
        let object;

        // 재사용 가능한 오브젝트가 있는지 확인
        if (this.pool.has(key) && this.pool.get(key).length > 0) {
            object = this.pool.get(key).pop();
            object.visible = true;
        } else {
            // 새 오브젝트 생성
            object = createFn();
        }

        this.activeObjects.add(object);
        return object;
    }

    // 오브젝트 반환
    release(object) {
        if (!this.activeObjects.has(object)) return;

        object.visible = false;
        this.activeObjects.delete(object);

        // 오브젝트 종류별로 풀 관리
        const key = object.userData.modelType || 'default';
        if (!this.pool.has(key)) {
            this.pool.set(key, []);
        }

        const poolArray = this.pool.get(key);
        if (poolArray.length < this.maxSize) {
            poolArray.push(object);
        } else {
            // 풀이 가득 찼을 경우 리소스 정리
            this.disposeObject(object);
        }
    }

    // 사용하지 않는 오브젝트 정리
    cleanup() {
        this.pool.forEach((objects, key) => {
            while (objects.length > this.maxSize) {
                const object = objects.pop();
                this.disposeObject(object);
            }
        });
    }

    // 리소스 정리
    disposeObject(object) {
        if (object.geometry) {
            object.geometry.dispose();
        }
        if (object.material) {
            if (Array.isArray(object.material)) {
                object.material.forEach(material => material.dispose());
            } else {
                object.material.dispose();
            }
        }
        object.clear();
    }

    // 모든 활성 오브젝트 정리
    disposeAll() {
        this.activeObjects.forEach(object => {
            this.release(object);
        });
        this.pool.forEach(objects => {
            objects.forEach(object => {
                this.disposeObject(object);
            });
        });
        this.pool.clear();
        this.activeObjects.clear();
    }

    // 오브젝트 풀 미리 채우기
    prewarm(modelType, model) {
        if (!this.pool.has(modelType)) {
            this.pool.set(modelType, []);
        }

        const poolArray = this.pool.get(modelType);
        if (poolArray.length < this.maxSize) {
            model.visible = false;
            model.userData.modelType = modelType;
            poolArray.push(model);
        }
    }

    // 풀의 준비 상태 확인
    isReady(modelType) {
        return this.pool.has(modelType) && this.pool.get(modelType).length > 0;
    }
}

// 싱글톤 인스턴스
const objectPool = new ObjectPool();
export default objectPool;
