import React, { useState, useEffect, useCallback, Suspense, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/cannon';
import CameraController from './components/CameraController';
import {
  GAME_CONSTANTS,
  GAME_PHASES,
  CAMERA_SETTINGS,
  LIGHT_SETTINGS,
  SCENE_SETTINGS,
  BOX_PHYSICS
} from './gameConstants';
import {
  BoxUtils,
  CollisionManager,
  ScoreManager,
  GameStateManager
} from './gameLogic';
import Box from './Box';
import Plane from './Plane';
import Background from './components/Background';
import ShareButton from './components/ShareButton';
import AudioControls from './components/AudioControls';
import Tutorial from './Tutorial';
import TutorialButton from './components/TutorialButton';
import * as THREE from 'three'; // Import the entire THREE library
import SoundEffects from './components/SoundEffects';
import LoadingManager from './components/LoadingManager';
import { 
  EffectComposer, 
  Bloom, 
  DepthOfField, 
  Noise,
  Glitch,
  Grid,
  DotScreen,
  Scanline,
  Pixelation,
  ChromaticAberration, 
  HueSaturation, 
  Vignette, 
  SMAA 
} from '@react-three/postprocessing';
import { BlendFunction, GlitchMode } from 'postprocessing';
import { Environment, Lightformer } from '@react-three/drei';
import BoxSpawnQueue from './utils/BoxSpawnQueue';
import './styles.css';

const App = () => {
  const [boxes, setBoxes] = useState([]);
  const [gamePhase, setGamePhase] = useState(GAME_PHASES.INITIAL);
  const [isGameOver, setIsGameOver] = useState(false);  // 추가
  const [score, setScore] = useState(0);
  const [lastBoxIndex, setLastBoxIndex] = useState(-1);
  const [showTutorial, setShowTutorial] = useState(true);
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);  // 물리 엔진 일시 중지 상태

  const isSpawningRef = useRef(false);
  const spawnTimeoutRef = useRef(null);
  const stabilityCheckTimeout = useRef(null);
  const spotLightRef = useRef(null);
  const [showCouponModal, setShowCouponModal] = useState(false);

  const updateGamePhase = useCallback((newPhase) => {
    console.log('Attempting to change phase from', gamePhase, 'to', newPhase);
    
    // 게임오버 상태에서는 상태 변경 불가
    if (gamePhase === GAME_PHASES.GAME_OVER) {
      console.log('Cannot change phase in GAME_OVER state');
      return;
    }
    
    if (GameStateManager.canChangeGamePhase(gamePhase, newPhase)) {
      console.log(`Game phase changing from ${gamePhase} to ${newPhase}`);
      
      if (newPhase === GAME_PHASES.GAME_OVER) {
        console.log('Entering GAME_OVER state');
        setIsPaused(true);
        BoxSpawnQueue.clear();
        isSpawningRef.current = false;
        
        if (spawnTimeoutRef.current) {
          clearTimeout(spawnTimeoutRef.current);
          spawnTimeoutRef.current = null;
        }
        if (stabilityCheckTimeout.current) {
          clearTimeout(stabilityCheckTimeout.current);
          stabilityCheckTimeout.current = null;
        }
        
        setBoxes(prevBoxes => 
          prevBoxes.map(box => ({ ...box, isMoving: false }))
        );
      }
      
      setGamePhase(newPhase);
    } else {
      console.log('Cannot change phase from', gamePhase, 'to', newPhase);
    }
  }, [gamePhase]);

  const isGameOverRef = useRef(false);

    useEffect(() => {
      isGameOverRef.current = gamePhase === GAME_PHASES.GAME_OVER;
    }, [gamePhase]);

  const spawnNewBox = useCallback(() => {
    // 게임오버 상태에서는 새 박스 생성 방지
    if (gamePhase === GAME_PHASES.GAME_OVER) {
      console.log('Cannot spawn new box in GAME_OVER state');
      return;
    }

    if (!GameStateManager.canSpawnNewBox(gamePhase)) {
      console.log('Cannot spawn new box in current phase:', gamePhase);
      return;
    }

    if (isSpawningRef.current) {
      console.log('Already spawning a box');
      return;
    }

    console.log('Box stacked - preparing to spawn new box');
    isSpawningRef.current = true;

    const spawnFunction = () => {
      console.log('Timeout completed - attempting to spawn new box');
      const newBoxIndex = lastBoxIndex + 1;
      const newBox = BoxUtils.createNewBox(newBoxIndex, boxes);
      
      setBoxes(prevBoxes => [...prevBoxes, newBox]);
      setLastBoxIndex(newBoxIndex);
      updateGamePhase(GAME_PHASES.BOX_MOVING);
      isSpawningRef.current = false;
    };

    spawnTimeoutRef.current = setTimeout(() => {
      BoxSpawnQueue.enqueue(spawnFunction);
    }, GAME_CONSTANTS.SPAWN_DELAY);
  }, [gamePhase, lastBoxIndex, updateGamePhase]);

  const scheduleNewBoxSpawn = useCallback(() => {
    if (gamePhase === GAME_PHASES.GAME_OVER) return;
    if (gamePhase !== GAME_PHASES.BOX_STACKED) return;

    console.log('scheduleNewBoxSpawn called, gamePhase:', gamePhase);
    const spawnFunction = () => new Promise(resolve => {
      if (!isSpawningRef.current) {
        isSpawningRef.current = true;
        spawnTimeoutRef.current = setTimeout(() => {
          spawnNewBox();
          isSpawningRef.current = false;
          resolve();
        }, GAME_CONSTANTS.STABILITY_CHECK_DELAY);
      } else {
        resolve();
      }
    });

    BoxSpawnQueue.enqueue(spawnFunction);
  }, [gamePhase, spawnNewBox]);

  const resetGame = useCallback(() => {
    console.log("게임 리셋");
    // 모든 타이머와 큐를 먼저 정리
    BoxSpawnQueue.clear();
    isSpawningRef.current = false;
    
    if (spawnTimeoutRef.current) {
      clearTimeout(spawnTimeoutRef.current);
      spawnTimeoutRef.current = null;
    }
    if (stabilityCheckTimeout.current) {
      clearTimeout(stabilityCheckTimeout.current);
      stabilityCheckTimeout.current = null;
    }

    // 게임 상태 초기화
    setBoxes([]);
    setLastBoxIndex(-1);
    setScore(0);
    setIsPaused(false);
    setIsGameOver(false);
    setGamePhase(GAME_PHASES.INITIAL);

    // 리셋 후 첫 번째 박스 생성
    setTimeout(() => {
      if (!isSpawningRef.current) {  // 추가 안전장치
        const newBox = BoxUtils.createNewBox(0);
        setBoxes([newBox]);
        updateGamePhase(GAME_PHASES.BOX_MOVING);
        setLastBoxIndex(0);
        console.log('초기 상태 - 첫 번째 박스 생성');
      }
    }, 100);
  }, []);

  const handleLand = useCallback(({ index, collidedWith, position, isGameOver: boxIsGameOver }) => {
    if (isGameOverRef.current) {
      console.log('Game phase is GAME_OVER, exiting handleLand.');
      return;
    }

    console.log('handleLand called with position:', position);

    const prevPhase = gamePhase;
    
    if (boxIsGameOver) {
      console.log('Game Over - Box fell');
      if (!isGameOver) {
          setIsGameOver(true);
          updateGamePhase(GAME_PHASES.GAME_OVER);
      }
      return;
  }
    
    // 바닥과의 충돌 먼저 체크
    if (collidedWith === 'plane') {
      if (lastBoxIndex === 0) {
        console.log('First box landed on plane - switching to BOX_STACKED');
        // 첫 번째 박스의 isStacked 상태를 true로 변경
        setBoxes(prevBoxes => {
          const newBoxes = [...prevBoxes];
          if (newBoxes.length > 0) {
            newBoxes[0] = {
              ...newBoxes[0],
              isStacked: true,
              isMoving: false
            };
          }
          return newBoxes;
        });
        updateGamePhase(GAME_PHASES.BOX_STACKED);
      } else {
        console.log('Non-first box hit plane - GAME OVER');
        updateGamePhase(GAME_PHASES.GAME_OVER);
      }
      return;
    }
    
    // 현재 움직이는 박스 멈추기
    setBoxes(prevBoxes => {
      const newBoxes = [...prevBoxes];
      if (lastBoxIndex >= 0 && lastBoxIndex < newBoxes.length) {
        // position이 배열인 경우 객체로 변환
        const newPosition = Array.isArray(position) 
          ? { x: position[0], y: position[1], z: position[2] }
          : position;

        newBoxes[lastBoxIndex] = {
          ...newBoxes[lastBoxIndex],
          isMoving: false,
          position: newPosition
        };
      }
      return newBoxes;
    });

    // 안정성 체크 시작
    if (stabilityCheckTimeout.current) {
      clearTimeout(stabilityCheckTimeout.current);
    }

    stabilityCheckTimeout.current = setTimeout(() => {
      // 현재 박스와 이전 박스의 상태 가져오기
      const currentBox = boxes[lastBoxIndex];
      const previousBox = lastBoxIndex > 0 ? boxes[lastBoxIndex - 1] : null;

      if (!currentBox) {
        console.log('Current box not found - GAME OVER');
        updateGamePhase(GAME_PHASES.GAME_OVER);
        return;
      }

      const isStable = BoxUtils.checkBoxStability(currentBox, previousBox);
      console.log('Stability check result:', { isStable, currentBox, previousBox });
      
      if (!isStable) {
        console.log('Box not stable - GAME OVER');
        updateGamePhase(GAME_PHASES.GAME_OVER);
        return;
      }      

      // 박스가 안정적으로 쌓였을 때
      console.log('Box stacked successfully');
      setScore(prevScore => ScoreManager.calculateScore(prevScore, lastBoxIndex));
      
      // 현재 박스의 isStacked 상태를 true로 변경
      setBoxes(prevBoxes => {
        const newBoxes = [...prevBoxes];
        if (lastBoxIndex >= 0 && lastBoxIndex < newBoxes.length) {
          newBoxes[lastBoxIndex] = {
            ...newBoxes[lastBoxIndex],
            isStacked: true
          };
        }
        return newBoxes;
      });
      updateGamePhase(GAME_PHASES.BOX_STACKED);
    }, GAME_CONSTANTS.STABILITY_CHECK_DELAY);
  }, [gamePhase, lastBoxIndex, boxes, updateGamePhase]);

  const handleCollision = useCallback((event) => {
    if (!isSpawningRef.current || isGameOverRef.current) {
      return;
    }

    const collidedWith = event.body.name;
    if (!collidedWith) return;

    const currentBox = boxes[lastBoxIndex];
    if (!currentBox) return;

    const collisionResult = CollisionManager.handleCollision({
      index: lastBoxIndex,
      collidedWith,
      position: currentBox.position,
      boxes,
      lastBoxIndex,
      gamePhase
    });

    if (!collisionResult) return;

    if (collisionResult.gamePhase === GAME_PHASES.GAME_OVER) {
      console.log('Collision triggered GAME OVER');
      updateGamePhase(GAME_PHASES.GAME_OVER);
      return;
    }

    if (collisionResult.needsStabilityCheck) {
      handleLand(collisionResult.position);
    } else if (collisionResult.gamePhase) {
      updateGamePhase(collisionResult.gamePhase);
    }
  }, [boxes, lastBoxIndex, gamePhase, handleLand, updateGamePhase]);

  const handleCanvasClick = useCallback(() => {
    console.log("캔버스 클릭", { gamePhase, boxesLength: boxes.length });
    if (gamePhase === GAME_PHASES.GAME_OVER) {
      resetGame();
    } else if (gamePhase === GAME_PHASES.BOX_MOVING && boxes.length > 0) {
      const lastBox = boxes[boxes.length - 1];
      if (lastBox.isMoving) {
        setBoxes(prevBoxes =>
          prevBoxes.map((box, index) =>
            index === prevBoxes.length - 1 ? { ...box, isMoving: false } : box
          )
        );
        updateGamePhase(GAME_PHASES.BOX_LANDING);
      }
    }
  }, [gamePhase, boxes, resetGame]);

  const handleCopyAndRedirect = () => {
    navigator.clipboard.writeText('GAME50').then(() => {
      const message = document.createElement('div');
      message.className = 'copy-message';
      message.textContent = '쿠폰 코드가 복사되었습니다!';
      document.body.appendChild(message);
      setTimeout(() => message.remove(), 2000);
      window.open('https://www.crocs.co.kr/', '_blank');
      setShowCouponModal(false);
    });
  };

  const handleCloseTutorial = useCallback(() => {
    setShowTutorial(false);
  }, []);

  const handleOpenTutorial = useCallback(() => {
    setShowTutorial(true);
  }, []);

  const handleLoadComplete = useCallback(() => {
    setIsAssetsLoaded(true);
  }, []);

  const startGame = useCallback(() => {
    if (!isAssetsLoaded) {
      console.log('Assets still loading...');
      return;
    }
    
    updateGamePhase(GAME_PHASES.PLAYING);
    spawnNewBox();
  }, [isAssetsLoaded, spawnNewBox]);

  // gamePhase 변경 감지
  useEffect(() => {
    // 게임 페이즈가 변경될 때마다 호출
    console.log('gamePhase changed to:', gamePhase);

    // 게임오버 상태일 때 모든 진행 중인 작업 중지
    if (gamePhase === GAME_PHASES.GAME_OVER) {
      BoxSpawnQueue.clear();
      isSpawningRef.current = false;
      
      if (spawnTimeoutRef.current) {
        clearTimeout(spawnTimeoutRef.current);
        spawnTimeoutRef.current = null;
      }
      if (stabilityCheckTimeout.current) {
        clearTimeout(stabilityCheckTimeout.current);
        stabilityCheckTimeout.current = null;
      }
      return;
    }

    if (gamePhase === GAME_PHASES.BOX_STACKED) {
      console.log('Box stacked - preparing to spawn new box');
      spawnNewBox();
    }
  }, [gamePhase, spawnNewBox]);

  const addBox = useCallback(() => {
    const newBoxIndex = boxes.length;
    const newBox = {
      key: newBoxIndex,
      position: [
        0,
        BOX_PHYSICS.SPAWN.INITIAL_HEIGHT + (newBoxIndex * BOX_PHYSICS.SPAWN.HEIGHT_INCREMENT),
        0
      ],
      isMoving: true,
      isStacked: false
    };
    setBoxes(prevBoxes => [...prevBoxes, newBox]);
    setLastBoxIndex(newBoxIndex);
  }, [boxes.length]);

  useEffect(() => {
    return () => {
      if (spawnTimeoutRef.current) {
        clearTimeout(spawnTimeoutRef.current);
      }
      if (stabilityCheckTimeout.current) {
        clearTimeout(stabilityCheckTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const animate = () => {
      requestAnimationFrame(animate);
    };
    animate();
    return () => cancelAnimationFrame(animate);
  }, []);

  // 초기 박스 생성
  useEffect(() => {
    if (gamePhase === GAME_PHASES.INITIAL && boxes.length === 0) {
      console.log('초기 상태 - 첫 번째 박스 생성');
      const newBox = BoxUtils.createNewBox(0);
      setBoxes([newBox]);
      updateGamePhase(GAME_PHASES.BOX_MOVING);
      setLastBoxIndex(0);
    }
  }, [gamePhase, boxes.length]);

  return (
    <div className="main-wrapper">
      <div className="app-container">
        {!isAssetsLoaded && <LoadingManager onLoadComplete={handleLoadComplete} />}
        <AudioControls />
        {/* 좌측 영역 */}
        <div className="left-section">                 
            <div className="Title">크록스, <br/>겨울을 부탁해!</div>
        </div>

        {/* 중앙 게임 영역 */}
        <div className="game-section">
          <div className="game-canvas-container">
            <Canvas
              shadows // 그림자 활성화
              camera={{
                position: CAMERA_SETTINGS.initialPosition,
                fov: CAMERA_SETTINGS.fov,
                near: CAMERA_SETTINGS.near,
                far: CAMERA_SETTINGS.far
              }}
              onClick={handleCanvasClick}
              gl={{
                preserveDrawingBuffer: true,
                antialias: true,
                alpha: true,
                outputColorSpace: THREE.SRGBColorSpace,
                toneMapping: THREE.ACESFilmicToneMapping,
                toneMappingExposure: 0.8,
                shadowMap: { 
                  enabled: true,
                  type: THREE.PCFSoftShadowMap
                }
              }}
            >
              <Suspense fallback={null}>
                <fog
                  attach="fog"
                  args={[
                    SCENE_SETTINGS.fog.color,
                    SCENE_SETTINGS.fog.near,
                    SCENE_SETTINGS.fog.far
                  ]}
                />
                <Background />
                {/* 은은한 메인 앰비언트 라이트 */}
                <ambientLight intensity={1} color="#ffffff" />

                {/* 대기 디렉셔널 라이트 */}
                <directionalLight
                  position={[10, 15, 5]}
                  intensity={5.5}
                  color="#ffffff"
                  castShadow
                  shadow-mapSize={[512, 512]}
                  shadow-camera-left={-10}
                  shadow-camera-right={10}
                  shadow-camera-top={10}
                  shadow-camera-bottom={-10}
                  shadow-camera-near={0.5}
                  shadow-camera-far={50}
                  shadow-bias={-0.001}
                />
                
                {/* 메인 스포트라이트 - 부드러운 주 광원 
                <spotLight
                  position={[5, 15, 5]}
                  angle={0.6}
                  penumbra={0.8}
                  intensity={50}
                  color="#ffffff"
                  castShadow
                  shadow-mapSize={[512, 512]}
                  shadow-bias={-0.001}
                  shadow-radius={4}
                  shadow-blurSamples={8}
                />*/}

                {/* 보조 스포트라이트 - 반대편 채움광 
                <spotLight
                  position={[-5, 12, -5]}
                  angle={0.8}
                  penumbra={1}
                  intensity={10}
                  color="#e1e1ff"
                  castShadow={false}
                /> */}

                {/* 하단 채움광 
                <pointLight
                  position={[0, -2, 0]}
                  intensity={1}
                  color="#ffffff"
                  distance={10}
                  decay={2}
                />*/}
                
                <CameraController boxCount={boxes.length} gamePhase={gamePhase} />
                <Physics 
                  gravity={GAME_CONSTANTS.GRAVITY}
                  paused={isPaused}  // 물리 엔진 일시 중지 상태 적용
                  onCollision={handleCollision}
                >
                  <Plane />
                  {boxes.map((box) => (
                    <Box
                      key={box.id}
                      position={box.position}
                      color={box.color}
                      isMoving={box.isMoving}
                      index={box.index}
                      gamePhase={gamePhase}
                      isStacked={box.isStacked}
                      onLand={handleLand}
                    />
                  ))}
                </Physics>

                <EffectComposer>
                  {/* 메인 블룸 효과 */}
                  <Bloom 
                    intensity={0.2}
                    luminanceThreshold={0.8}
                    luminanceSmoothing={0.9}
                    mipmapBlur={true}
                  />
                  
                  {/* 색상 보정 */}
                  <HueSaturation
                    hue={0} 
                    saturation={0.1}
                  />
                  
                  {/* 비네트 효과로 가장자리 강조 
                  <Vignette
                    offset={0.5}
                    darkness={0.5}
                    opacity={0.4}
                  /> */}

                  {/* SMAA 안티앨리어싱 */}
                  <SMAA />
                </EffectComposer>
              </Suspense>
            </Canvas>
            <div className="game-ui">
              <div className="score">Score: {score}</div>
            </div>
            <TutorialButton onClick={handleOpenTutorial} />
            <ShareButton />
            {GameStateManager.checkGameOver(gamePhase) && (
              <div className="game-over-ui">
                <img src="/images/2019_CROCS_LOGO_White 1.png" alt="Crocs Logo" className="game-over-logo" />
                <div className="final-score">
                 <p>Game Over</p> 
                  최종 점수: {score}
                </div>
                <button className="check-coupon-button" onClick={() => setShowCouponModal(true)}>
                  쿠폰 확인하기
                </button>
                <button className="reset-button" onClick={resetGame}>
                  다시하기 
                </button>
              </div>
            )}

            {showCouponModal && (
              <div className="coupon-modal-overlay" onClick={() => setShowCouponModal(false)}>
                <div className="coupon-modal" onClick={e => e.stopPropagation()}>
                  <button className="close-button" onClick={() => setShowCouponModal(false)}>×</button>
                  <div className="title">특별 할인 쿠폰</div>
                  <p className="description">크록스코리아 공식 앱에서 사용 가능한<br />특별 할인 쿠폰이 도착했습니다!</p>
                  <div className="code" onClick={handleCopyAndRedirect}>CG24</div>
                  <p className="description">코드를 클릭하면 자동으로 복사됩니다.</p>
                </div>
              </div>
            )}
            {showTutorial && <Tutorial onClose={handleCloseTutorial} />}
          </div>
        </div>

        {/* 우측 영역 */}
        <div className="right-section">
          <div className="content">
            <p>더 편안하게, 더 따뜻하게.</p>
            <p>크록스의 클래식 라인드 컬렉션과</p>
            <p>함께 더 따뜻한 연말을 보내볼까요?</p>
            <br />
            <p>게임을 통해</p>
            <p>나의 겨울 OOTD와 딱 맞는 크록스 슈즈,</p>
            <p>그리고 지비츠™ 참을 만나보세요.</p>
            <br/>
            <p>인스타그램에서 진행 중인</p>
            <p>게임 인증샷 이벤트에 참여해주시면,</p> 
            <p> 추첨을 통해</p>
            <p>다양한 선물을 증정해드립니다.</p>

            <div className="social-links">
              <a href="https://www.crocs.co.kr/" target="_blank" rel="noopener noreferrer" className="social-button">
                <img src="/images/Btn_crocsmall.png" alt="크록스 공식몰" className="social-img" />
              </a>
              <a href="https://www.instagram.com/crocskorea/" target="_blank" rel="noopener noreferrer" className="social-button">
                <img src="/images/Btn_instar.png" alt="크록스 인스타그램" className="social-img" />
              </a>
              <div className="app-link">
                <a href='https://play.google.com/store/apps/details?id=com.crocs.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' className='app-button' >
                  <img alt='Get it on Google Play' src='/images/google-play-badge.png'/>
                </a>
                <a href="https://apps.apple.com/kr/app/crocs/id1588314651?itscg=30200&itsct=apps_box_badge&mttnsubad=1588314651" target='_blank' className='app-button-apple' >
                  <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1667001600" alt="Download on the App Store" />
               </a>
              </div>   
                  
            </div>
          </div>
        </div>   
      </div> 

      <div className="footer-container">
        <img src="/images/2019_CROCS_LOGO_White 1.png" alt="Crocs Logo" className="footer-logo" />
      </div>

    </div>
       

  );
};

export default App;
