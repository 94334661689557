import React, { useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { MODEL_FILES } from '../gameConstants';
import objectPool from '../utils/ObjectPool';
import { optimizeModel } from '../Box';
import '../styles.css';
import '../loading.css';

const POOL_SIZE = 3; // 각 모델 타입별로 미리 생성할 개수

// Preload all models with correct path
MODEL_FILES.forEach(modelFile => {
    useGLTF.preload(`/models/${modelFile}`);
});

const LoadingManager = ({ onLoadComplete }) => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('Loading models...');
    const [currentModelIndex, setCurrentModelIndex] = useState(0);

    // Load models one at a time using hooks properly with correct path
    const { scene: currentModel } = useGLTF(`/models/${MODEL_FILES[currentModelIndex]}`);

    useEffect(() => {
        let loadedCount = 0;
        const totalModels = MODEL_FILES.length * POOL_SIZE;

        const processCurrentModel = () => {
            try {
                const modelFile = MODEL_FILES[currentModelIndex];
                setStatus(`Loading ${modelFile}...`);

                // Create pool instances for current model
                for (let i = 0; i < POOL_SIZE; i++) {
                    const optimizedModel = optimizeModel(currentModel);
                    objectPool.prewarm(`/models/${modelFile}`, optimizedModel);
                    loadedCount++;
                    setProgress((loadedCount / totalModels) * 100);
                }

                // Move to next model or complete
                if (currentModelIndex < MODEL_FILES.length - 1) {
                    setCurrentModelIndex(prev => prev + 1);
                } else {
                    setStatus('Loading complete!');
                    onLoadComplete();
                }
            } catch (error) {
                console.error('Error processing model:', error);
                setStatus('Error loading models');
            }
        };

        processCurrentModel();
    }, [currentModel, currentModelIndex, onLoadComplete]);

    return (
        <div className="loading-screen">
            <div className="loading-content">
                <div className="loading-title">Loading Game Assets</div>
                <div className="loading-bar-container">
                    <div className="loading-bar" style={{ width: `${progress}%` }} />
                </div>
                <div className="loading-text">{status}</div>
            </div>
        </div>
    );
};

export default LoadingManager;
