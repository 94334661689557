// 게임 기본 상수
export const GAME_CONSTANTS = {
    BOX_WIDTH: 1,    // 가로 길이 증가
    BOX_HEIGHT: 1, // 높이 감소
    BOX_DEPTH: 1,    // 깊이
    STABILITY_CHECK_DELAY: 2000, // 2초로 증가 (기존 500ms)
    STABILITY_THRESHOLD_RATIO: 10,  // 박스 크기의 70%까지만 허용
    SCORE_PER_BOX: 10,    
    GRAVITY: [0, -9.8, 0],
    COLLISION_COOLDOWN: 300  // 충돌 감지 쿨다운 (ms) 추가
};

// 게임 상태
export const GAME_PHASES = {
    INITIAL: 'initial',
    BOX_MOVING: 'boxMoving',
    BOX_LANDING: 'boxLanding',
    BOX_STACKED: 'boxStacked',
    GAME_OVER: 'gameOver'
};

// 모델 파일 목록
export const MODEL_FILES = [
    'Box_green_optimized.glb',
    'Box_jewel_new_optimized.glb',
    'Box_star_optimized.glb',
    'Box_stick_optimized.glb',
    'Box_pupple_optimized.glb',
    'Box_ginger_optimized.glb',
    'Box_yellow_optimized.glb',
    'Box_pink_optimized.glb'
];

export const MODEL_SETTINGS = {
    SCALE: 24,     // 모델의 기본 스케일 (충돌체 크기와 맞춤)
    ROTATION: {
        X: 0,      // X축 회전 (라디안)
        Y: -Math.PI/2,  // Y축 회전 (45도를 라디안으로 변환)
        Z: 0       // Z축 회전 (라디안)
    },
    // 실제 측정된 바운딩 박스 크기
    BOUNDING_BOX: {
        WIDTH: 1.2,    // X
        HEIGHT: 1.2,   // Y
        DEPTH: 1.2     // Z
    }
};

// 박스 물리 설정
export const BOX_PHYSICS = {
    MOVEMENT: {
        AMPLITUDE: 3.2,      // 좌우 움직임 범위
        FREQUENCY: 1,      // 움직임 속도 (높을수록 빠름)
        MIN_X: -5,          // 최소 X 위치
        MAX_X: 5            // 최대 X 위치
    },
    // 물리 관련 설정
    MASS: {
        STATIC: 1.0,        // 정지 상태의 질량 1로 더 가볍게
        MOVING: 0           // 이동 중인 상태의 질량
    },
    GRAVITY: {
        x: 0,              // X축 중력
        y: -9.8,           // Y축 중력 (기본 지구 중력)
        z: 0               // Z축 중력
    },
    SPAWN: {
        INITIAL_HEIGHT: 5,    // 첫 번째 박스의 높이
        HEIGHT_INCREMENT: 1.2    // 각 박스마다 증가하는 높이
    }
};

// 카메라 설정
export const CAMERA_SETTINGS = {
    initialPosition: [8, 7, 8], // x와 z를 줄여서 더 가깝게, y를 낮춰서 더 완만하게
    heightOffset: 1.5,  // 박스당 카메라 상승 높이
    fov: 50,
    near: 0.1,
    far: 1000
};

// 라이트 설정
export const LIGHT_SETTINGS = {
    ambient: {
        intensity: 0.8,
        color: '#ffffff'
    },
    spot: {
        position: [4, 8, 10],
        angle: 0.6,
        intensity: 8,
        penumbra: 0.5,
        castShadow: true,
        distance: 70,
        color: '#ffffff',
        shadow: {
            mapSize: {
                width: 2048,    // 그림자 맵 크기 증가
                height: 2048
            },
            camera: {
                near: 0.5,
                far: 80
            },
            bias: -0.001,
            radius: 4,        // 그림자 블러 증가
            blurSamples: 16   // 블러 샘플 수 증가
        }
    },
    directional: {
        position: [10, 10, 5],
        intensity: 1.5,
        color: '#ffffff',
        shadow: {           // 디렉셔널 라이트에도 쉐도우 추가
            mapSize: {
                width: 2048,
                height: 2048
            },
            camera: {
                near: 0.5,
                far: 80,
                left: -15,
                right: 15,
                top: 15,
                bottom: -15
            },
            bias: -0.001,
            radius: 4
        }
    }
};

// 씬 설정
export const SCENE_SETTINGS = {
    fog: {
        color: '#BFDFFF',
        near: 10,          // 안개 시작 거리를 더 멀리 설정
        far: 50,          // 안개가 완전히 덮는 거리를 더 가깝게 설정
        mobile: {
            enabled: true,  // 모바일에서 안개 효과 활성화 여부
            near: 15,      // 모바일에서는 더 멀리서 시작
            far: 35        // 모바일에서는 더 가깝게 끝남
        }
    },
    background: {
        topColor: '#99C7E8',
        bottomColor: '#FFFFFF',
        snow: {
            count: window.innerWidth < 768 ? 400 : 800,  // 모바일에서는 눈송이 개수 감소
            size: 0.2,
            speed: 0.1,
            swaySpeed: 0.1,
            color: '#ffffff',
            opacity: 0.8,
            area: {
                width: 100,
                height: 50,
                depth: 50
            }
        }
    },
    plane: {
        color: '#ffffff',
        emissive: '#303030'
    }
};