import {
    GAME_CONSTANTS,
    GAME_PHASES,
    MODEL_FILES,
    BOX_PHYSICS
} from './gameConstants';

// 박스 관련 유틸리티
export const BoxUtils = {
    // 박스 크기에 따른 임계값 계산
    getStabilityThreshold: () => {
        return GAME_CONSTANTS.BOX_WIDTH * GAME_CONSTANTS.STABILITY_THRESHOLD_RATIO;
    },

    // 박스 크기에 따른 충돌체 크기 반환
    getBoxDimensions: () => {
        return [
            GAME_CONSTANTS.BOX_WIDTH,
            GAME_CONSTANTS.BOX_HEIGHT,
            GAME_CONSTANTS.BOX_DEPTH
        ];
    },

    calculateDistance: (pos1, pos2) => {
        return Math.sqrt(
            Math.pow(pos1[0] - pos2[0], 2) +
            Math.pow(pos1[2] - pos2[2], 2)
        );
    },

    checkBoxStability: (currentBox, previousBox) => {
        // 첫 번째 박스는 항상 안정적
        if (!previousBox) {
            console.log('First box - automatically stable');
            return true;
        }

        if (!currentBox?.position) {
            console.log('Missing current box position data');
            return false;
        }

        // 위치 데이터 정규화 함수
        const normalizePosition = (pos) => {
            if (Array.isArray(pos)) {
                return { x: pos[0], z: pos[2] };
            }
            // position이 객체인 경우, x와 z 값이 있는지 확인
            if (pos.x !== undefined && pos.z !== undefined) {
                return { x: pos.x, z: pos.z };
            }
            // position이 객체이지만 배열 형태로 저장된 경우
            if (pos[0] !== undefined && pos[2] !== undefined) {
                return { x: pos[0], z: pos[2] };
            }
            console.log('Invalid position format:', pos);
            return { x: 0, z: 0 };
        };

        const currentPos = normalizePosition(currentBox.position);
        const previousPos = normalizePosition(previousBox.position);

        console.log('Position data:', {
            currentBoxPos: currentBox.position,
            previousBoxPos: previousBox.position,
            normalizedCurrent: currentPos,
            normalizedPrevious: previousPos
        });

        // 두 박스 사이의 거리 계산
        const distance = Math.sqrt(
            Math.pow(currentPos.x - previousPos.x, 2) +
            Math.pow(currentPos.z - previousPos.z, 2)
        );

        const threshold = GAME_CONSTANTS.BOX_WIDTH * GAME_CONSTANTS.STABILITY_THRESHOLD_RATIO;
        const isStable = distance <= threshold;

        console.log('안정성 체크:', {
            currentPos,
            previousPos,
            distance,
            threshold,
            isStable,
            ratio: distance / GAME_CONSTANTS.BOX_WIDTH
        });

        return isStable;
    },

    // 이전에 사용된 모델 인덱스를 저장
    lastUsedModels: new Set(),

    // 새로운 모델 선택 로직
    getRandomModel: function() {
        let availableModels = [];
        
        // 모든 모델이 사용되었다면 초기화
        if (this.lastUsedModels.size >= MODEL_FILES.length - 1) {
            this.lastUsedModels.clear();
        }

        // 아직 사용되지 않은 모델들을 찾음
        availableModels = MODEL_FILES.filter((_, index) => !this.lastUsedModels.has(index));
        
        // 사용 가능한 모델 중에서 랜덤 선택
        const randomIndex = Math.floor(Math.random() * availableModels.length);
        const selectedModelIndex = MODEL_FILES.indexOf(availableModels[randomIndex]);
        
        // 선택된 모델 인덱스 저장
        this.lastUsedModels.add(selectedModelIndex);
        
        return MODEL_FILES[selectedModelIndex];
    },

    createNewBox: (boxCount) => {
        const dimensions = BoxUtils.getBoxDimensions();
        const movementRange = 6; // 박스가 움직일 수 있는 최대 범위 (플레인 크기 16에서 박스 크기 2와 여유 공간 고려)
        const randomX = (Math.random() * (movementRange * 2)) - movementRange; // -6에서 6 사이의 랜덤 위치
        const heightOffset = BOX_PHYSICS.SPAWN.HEIGHT_INCREMENT * boxCount;  // 박스 개수에 따라 높이 증가
        return {
            id: boxCount,
            index: boxCount,
            position: [randomX, BOX_PHYSICS.SPAWN.INITIAL_HEIGHT + heightOffset, 0],
            dimensions: dimensions,
            isMoving: true           
        };
    }
};

// 점수 관리
export const ScoreManager = {
    calculateScore: (currentScore, boxIndex) => {
        return currentScore + GAME_CONSTANTS.SCORE_PER_BOX;
    }
};

// 충돌 처리
export const CollisionManager = {
    handleCollision: ({ index, collidedWith, position, boxes, lastBoxIndex, gamePhase }) => {
        console.log('Collision detected:', { index, collidedWith, position, gamePhase });
        
        // 게임오버 상태에서는 충돌 처리 무시
        if (gamePhase === GAME_PHASES.GAME_OVER) {
            console.log('Game is over, ignoring collision');
            return null;
        }

        // 바닥과의 충돌을 먼저 체크
        if (collidedWith === 'plane') {
            if (index === 0) {
                console.log('First box landed on plane - switching to BOX_STACKED');
                return { gamePhase: GAME_PHASES.BOX_STACKED };
            } else {
                // 첫 번째가 아닌 박스가 바닥과 충돌하면 게임 오버
                console.log('Non-first box hit plane - game over');
                return { gamePhase: GAME_PHASES.GAME_OVER };
            }
        }

        // 마지막 박스가 아닌 경우 다른 충돌은 무시
        if (index !== lastBoxIndex) {
            console.log('Ignoring non-plane collision for non-last box');
            return null;
        }

        // 다른 박스와의 충돌
        if (collidedWith.startsWith('box')) {
            const collidedBoxIndex = parseInt(collidedWith.slice(3));
            console.log('Box collision:', { currentBox: index, collidedWith: collidedBoxIndex });

            // 직전 박스와의 충돌인 경우에만 안정성 체크
            if (collidedBoxIndex === index - 1) {
                console.log('Collision with previous box - checking stability');
                return { needsStabilityCheck: true, position };
            } else {
                // 직전 박스가 아닌 다른 박스와 충돌한 경우 게임 오버
                console.log('Collision with non-previous box - game over');
                return { gamePhase: GAME_PHASES.GAME_OVER };
            }
        }

        return null;
    }
};

// 게임 상태 관리
export const GameStateManager = {
    checkGameOver: (gamePhase) => {
        return gamePhase === GAME_PHASES.GAME_OVER;
    },

    canSpawnNewBox: (gamePhase) => {
        return gamePhase === GAME_PHASES.INITIAL || 
               gamePhase === GAME_PHASES.BOX_STACKED;
    },

    canChangeGamePhase: (currentPhase, newPhase) => {
        console.log('Checking phase change:', { currentPhase, newPhase });
        
        // 게임 오버 상태에서는 INITIAL 상태로만 변경 가능
        if (currentPhase === GAME_PHASES.GAME_OVER) {
            return newPhase === GAME_PHASES.INITIAL;
        }

        // 초기 상태에서는 BOX_MOVING으로만 변경 가능
        if (currentPhase === GAME_PHASES.INITIAL) {
            return newPhase === GAME_PHASES.BOX_MOVING;
        }

        // BOX_MOVING 상태에서는 BOX_LANDING, BOX_STACKED, GAME_OVER로 변경 가능
        if (currentPhase === GAME_PHASES.BOX_MOVING) {
            return newPhase === GAME_PHASES.BOX_LANDING || 
                   newPhase === GAME_PHASES.BOX_STACKED ||
                   newPhase === GAME_PHASES.GAME_OVER;
        }

        // BOX_LANDING 상태에서는 BOX_STACKED, GAME_OVER로 변경 가능
        if (currentPhase === GAME_PHASES.BOX_LANDING) {
            return newPhase === GAME_PHASES.BOX_STACKED || 
                   newPhase === GAME_PHASES.GAME_OVER;
        }

        // BOX_STACKED 상태에서는 BOX_MOVING, GAME_OVER로 변경 가능
        if (currentPhase === GAME_PHASES.BOX_STACKED) {
            return newPhase === GAME_PHASES.BOX_MOVING || 
                   newPhase === GAME_PHASES.GAME_OVER;
        }

        return false;
    }
};