import * as THREE from 'three';
import React, { useRef, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { SCENE_SETTINGS } from '../gameConstants';

// 눈송이 파티클 시스템
const Snow = () => {
    const { scene } = useThree();
    const snowRef = useRef();
    const time = useRef(0);

    // 눈송이 파티클 생성
    const particles = useMemo(() => {
        const snowSettings = SCENE_SETTINGS.background.snow;
        const geometry = new THREE.BufferGeometry();
        const positions = [];
        const velocities = [];

        for (let i = 0; i < snowSettings.count; i++) {
            // 랜덤한 위치에 눈송이 생성
            positions.push(
                Math.random() * snowSettings.area.width - snowSettings.area.width / 2,
                Math.random() * snowSettings.area.height,
                Math.random() * snowSettings.area.depth - snowSettings.area.depth / 2
            );
            // 각 눈송이의 개별 속도와 흔들림
            velocities.push(
                Math.random() * snowSettings.swaySpeed - snowSettings.swaySpeed / 2,
                -(Math.random() * snowSettings.speed + snowSettings.speed / 2),
                Math.random() * snowSettings.swaySpeed - snowSettings.swaySpeed / 2
            );
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute('velocity', new THREE.Float32BufferAttribute(velocities, 3));

        return geometry;
    }, []);

    // 눈송이 애니메이션
    useFrame(() => {
        time.current += 0.01;
        const snowSettings = SCENE_SETTINGS.background.snow;
        const positions = particles.attributes.position.array;
        const velocities = particles.attributes.velocity.array;

        for (let i = 0; i < positions.length; i += 3) {
            // 위치 업데이트
            positions[i] += velocities[i];     // x
            positions[i + 1] += velocities[i + 1]; // y
            positions[i + 2] += velocities[i + 2]; // z

            // 좌우 흔들림 추가
            positions[i] += Math.sin(time.current + positions[i]) * snowSettings.swaySpeed;

            // 바닥에 닿으면 다시 위로
            if (positions[i + 1] < -snowSettings.area.height / 2) {
                positions[i + 1] = snowSettings.area.height / 2;
                positions[i] = Math.random() * snowSettings.area.width - snowSettings.area.width / 2;
                positions[i + 2] = Math.random() * snowSettings.area.depth - snowSettings.area.depth / 2;
            }
        }

        particles.attributes.position.needsUpdate = true;
    });

    return (
        <points ref={snowRef}>
            <primitive object={particles} />
            <pointsMaterial
                size={SCENE_SETTINGS.background.snow.size}
                color={SCENE_SETTINGS.background.snow.color}
                transparent
                opacity={SCENE_SETTINGS.background.snow.opacity}
                depthWrite={false}
                sizeAttenuation={true}
            />
        </points>
    );
};

const Background = () => {
    const { scene } = useThree();

    // 배경 그라데이션 설정
    const canvas = document.createElement('canvas');
    canvas.width = 2;
    canvas.height = 512;

    const context = canvas.getContext('2d');
    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);

    // gameConstants.js의 색상값을 사용하여 Three.js Color 객체 생성
    const topColor = new THREE.Color(SCENE_SETTINGS.background.topColor);
    topColor.convertSRGBToLinear(); // sRGB to Linear conversion
    const bottomColor = new THREE.Color(SCENE_SETTINGS.background.bottomColor);
    bottomColor.convertSRGBToLinear(); // sRGB to Linear conversion

    gradient.addColorStop(0, topColor.getStyle());
    gradient.addColorStop(1, bottomColor.getStyle());

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const texture = new THREE.CanvasTexture(canvas);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;
    scene.background = texture;

    return <Snow />;
};

export default Background;