import React, { useRef, useMemo } from 'react';
import { useBox } from '@react-three/cannon';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { BoxUtils } from './gameLogic';
import { GAME_CONSTANTS, MODEL_FILES, MODEL_SETTINGS, BOX_PHYSICS, GAME_PHASES } from './gameConstants';
import * as THREE from 'three';
import objectPool from './utils/ObjectPool';

// LOD 설정 - 모든 디바이스에 동일하게 적용
const LOD_DISTANCES = {
    HIGH: 0,
    MEDIUM: 5,  // 거리 감소
    LOW: 10     // 거리 감소
};

// 전역 모델 캐시
const modelCache = new Map();

// 전역 cleanup 인터벌
let globalCleanupInterval = null;
let activeBoxes = new Set();

// cleanup 인터벌 시작
if (!globalCleanupInterval) {
    globalCleanupInterval = setInterval(() => {
        if (activeBoxes.size > 0) {
            objectPool.cleanup();
        }
    }, 30000);
}

// 모델 최적화 함수
export const optimizeModel = (scene) => {
    const clonedScene = scene.clone(true);
    
    clonedScene.traverse((node) => {
        if (node.isMesh) {
            // 새로운 머티리얼 인스턴스 생성
            const material = new THREE.MeshStandardMaterial().copy(node.material);
            
            if (node.name === 'Display_Base') {
                material.color = new THREE.Color('#ffffff');  
                material.transparent = true;
                material.opacity = 1;
                material.depthWrite = true;
                material.userData = {
                    emissiveColors: [
                        new THREE.Color('#ff3b3b').multiplyScalar(1),  
                        new THREE.Color('#2ec521').multiplyScalar(1)   
                    ]
                };
                material.emissive = material.userData.emissiveColors[0];  
                material.emissiveIntensity = 0;  
                material.metalness = 0.7;  
                material.roughness = 0.4;  
                material.envMapIntensity = 0.3;  
                material.clearcoat = 0.2;
                material.clearcoatRoughness = 0.3;
            } else if (node.name === 'Transparent_Box') {
                material.transparent = true;
                material.opacity = 0.15;
                material.emissive = new THREE.Color('#e5e586');
                material.emissiveIntensity = 0.2;  
                material.metalness = 0.6;
                material.roughness = 0.3;  
                material.envMapIntensity = 0.3;    
                material.clearcoat = 0.3;
                material.clearcoatRoughness = 0.3;
            }

            node.material = material;
            node.frustumCulled = true;
            
            // ObjectPool을 위한 메타데이터 추가
            node.userData.modelType = node.name;
        }
    });

    return clonedScene;
};


const Box = ({ position, isMoving, onLand, index, gamePhase, isStacked }) => {
    const startTime = useRef(Date.now());
    const currentPosition = useRef(position);
    const initialPosition = useRef(position);  // 초기 위치 저장
    const lastCollisionTime = useRef(0);
    const modelRef = useRef();
    const isDisposed = useRef(false);
    const hasBeenStacked = useRef(false);  
    const stackedColor = useRef(null);  
    const uniqueId = useRef(`box_${Date.now()}_${Math.random()}`);  
    const fallCheckTimeout = useRef(null);
    const fallStartTime = useRef(null);
    const isGameOver = useRef(false); // 게임 오버 상태 저장

    // 랜덤하게 모델 선택 및 로드
    const modelPath = useMemo(() => {
        const selectedModel = BoxUtils.getRandomModel();
        return `/models/${selectedModel}`;
    }, []);

    const { scene: originalScene } = useGLTF(modelPath);

    const model = useMemo(() => {
        if (!originalScene) return null;

        let optimizedModel;
        const cacheKey = `${modelPath}`;
        
        if (modelCache.has(cacheKey)) {
            optimizedModel = modelCache.get(cacheKey).clone();
        } else {
            optimizedModel = optimizeModel(originalScene);
            modelCache.set(cacheKey, optimizedModel);
        }

        // 각 인스턴스에 대해 새로운 머티리얼 생성
        optimizedModel.traverse((node) => {
            if (node.isMesh) {
                node.material = node.material.clone();  
                if (node.name === 'Display_Base') {
                    node.material.color = new THREE.Color('#ffffff');  
                    node.material.emissive = new THREE.Color('#ffffff');
                    node.material.emissiveIntensity = 0.1;  
                    node.material.metalness = 0.3;  
                    node.material.roughness = 0.8;  
                    node.userData.instanceId = uniqueId.current;
                }
            }
        });

        return optimizedModel;
    }, [modelPath]);

    const [ref, api] = useBox(() => ({
        mass: isMoving ? BOX_PHYSICS.MASS.MOVING : BOX_PHYSICS.MASS.STATIC,
        position,
        args: [MODEL_SETTINGS.BOUNDING_BOX.WIDTH, 
               MODEL_SETTINGS.BOUNDING_BOX.HEIGHT, 
               MODEL_SETTINGS.BOUNDING_BOX.DEPTH],
        friction: BOX_PHYSICS.FRICTION, // Ensure friction is set
        rotation: [
            MODEL_SETTINGS.ROTATION.X,
            MODEL_SETTINGS.ROTATION.Y,
            MODEL_SETTINGS.ROTATION.Z
        ],
        type: 'Dynamic',
        restitution: 0.0,        // 반발력 낮춤
        linearDamping: 0.8,      // 선형 감쇠 증가
        angularDamping: 0.3,     // 회전 감쇠 증가
        allowSleep: true,        // 정지 상태에서 물리 연산 최적화
        sleepSpeedLimit: 0.1,    // 정지 상태로 전환되는 속도 임계값
        sleepTimeLimit: 0.1,     // 정지 상태로 전환되는 시간
        fixedRotation: false,     // 회전 고정
        gravity: [
            BOX_PHYSICS.GRAVITY.x,
            BOX_PHYSICS.GRAVITY.y,
            BOX_PHYSICS.GRAVITY.z
        ],
        collisionResponse: gamePhase !== GAME_PHASES.GAME_OVER,
        onCollide: (e) => {
            const now = Date.now();
            if (now - lastCollisionTime.current < GAME_CONSTANTS.COLLISION_COOLDOWN) return;
            lastCollisionTime.current = now;

            const worldPosition = new THREE.Vector3();
            if (ref.current) {
                ref.current.getWorldPosition(worldPosition);
                currentPosition.current = [worldPosition.x, worldPosition.y, worldPosition.z];

                onLand({
                    index,
                    collidedWith: e.body.name,
                    position: currentPosition.current,
                });
            }
        },
    }));

    // 물리 상태 업데이트
    React.useEffect(() => {
        if (!api) return;  
        
        if (isMoving) {
            api.mass.set(BOX_PHYSICS.MASS.MOVING);
        } else {
            api.mass.set(BOX_PHYSICS.MASS.STATIC);
            api.velocity.set(0, 0, 0);
            api.angularVelocity.set(0, 0, 0);
        }
    }, [isMoving, api]);

    useFrame(() => {
        if (!ref.current || isDisposed.current) return;

        if (isStacked) {
            // 박스가 쌓였을 때 속도를 0으로 설정
            api.velocity.set(0, 0, 0);
            api.angularVelocity.set(0, 0, 0);
            // 박스가 쌓였을 때 질량을 0으로 설정
            api.mass.set(0);
        }
    });

    // Subscribe to position updates
    React.useEffect(() => {
        if (!api) return;

        // 초기 위치 설정
        initialPosition.current = position;
        console.log('Initial position set:', position);

        const unsubscribe = api.position.subscribe((p) => {
            // position이 Vector3 형태로 들어오는 경우
            if (p instanceof THREE.Vector3) {
                currentPosition.current = [p.x, p.y, p.z];
            }
            // position이 객체 형태로 들어오는 경우
            else if (p && typeof p === 'object' && 'x' in p && 'y' in p && 'z' in p) {
                currentPosition.current = [p.x, p.y, p.z];
            }
            // position이 배열 형태로 들어오는 경우
            else if (Array.isArray(p) && p.length >= 3) {
                currentPosition.current = [...p];
            }
            
            if (currentPosition.current) {
               // console.log('Position updated:', currentPosition.current);
        
                // 추락 상태 체크
                if (initialPosition.current) {
                    const yDiff = Math.abs(initialPosition.current[1] - currentPosition.current[1]);
                  /*  console.log('높이 차이 계산:', {
                        initial: initialPosition.current[1],
                        current: currentPosition.current[1],
                        diff: yDiff
                    });  */
                    
                    if (isMoving && yDiff > 4) {
                        console.log('Box is falling - GAME OVER', yDiff);
                        isMoving = false;  // 낙하 감지 후 더 이상의 상태 변경을 막음
                        // 게임 오버 상태를 저장하는 ref 추가
                        if (!isGameOver.current) {
                            isGameOver.current = true;
                            onLand({ 
                                index, 
                                collidedWith: 'fall', 
                                position: currentPosition.current,
                                isGameOver: true 
                            });
                        }
                    }
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, [api, position]);

    useFrame((state, delta) => {
        if (!ref.current || isDisposed.current) return;

        // 박스가 처음 쌓였을 때만 색상 변경
        if (isStacked && !isDisposed.current && !hasBeenStacked.current) {
            modelRef.current.traverse((node) => {
                if (node.isMesh && node.name === 'Display_Base' && 
                    node.userData.instanceId === uniqueId.current) {  
                    if (!stackedColor.current) {
                        const colors = [
                            new THREE.Color('#ff3b3b').multiplyScalar(2),  
                            new THREE.Color('#2ec521').multiplyScalar(2)   
                        ];
                        stackedColor.current = colors[Math.floor(Math.random() * colors.length)];
                    }
                    
                    node.material.emissive = stackedColor.current;
                    node.material.emissiveIntensity = 0.8;  
                    hasBeenStacked.current = true;
                }
            });
        }

        if (isMoving) {
            const elapsedTime = (Date.now() - startTime.current) / 1000;
            const { AMPLITUDE, FREQUENCY, MIN_X, MAX_X } = BOX_PHYSICS.MOVEMENT;
            const newX = AMPLITUDE * Math.sin(FREQUENCY * elapsedTime);
            const clampedX = Math.max(MIN_X, Math.min(MAX_X, newX));

            // 박스의 월드 위치 계산
            const worldPosition = new THREE.Vector3();
            ref.current.getWorldPosition(worldPosition);

            // 화면 좌표로 변환
            const screenPosition = worldPosition.clone();
            screenPosition.project(state.camera);

            // 화면 바깥으로 완전히 벗어났는지 또는 하단을 벗어났는지 체크
            const isCompletelyOutside = (
                screenPosition.x < -1.2 || 
                screenPosition.x > 1.2 || 
                screenPosition.y < -1.2 || 
                screenPosition.y > 1.2
            );
            
            const isBelowCamera = screenPosition.y < -2.0; // 화면 하단을 완전히 벗어난 경우

            if (isBelowCamera && isMoving && index > 0) {
                onLand({
                    index,
                    collidedWith: 'camera_bounds',
                    position: [worldPosition.x, worldPosition.y, worldPosition.z],
                    isGameOver: true
                });
                return;
            }

            // 현재 y 위치 유지하면서 x만 업데이트
            const [, y] = currentPosition.current;
            api.mass.set(0);
            api.position.set(clampedX, y || position[1], 0);
            api.velocity.set(0, 0, 0);
            api.angularVelocity.set(0, 0, 0);
            api.rotation.set(0, 0, 0);          
        }

        // LOD 처리 및 메모리 관리
        if (modelRef.current) {
            const distance = state.camera.position.distanceTo(modelRef.current.position);
            
            // isMoving이 true인 경우에는 절대 dispose하지 않음
            if (distance > 30 && !isDisposed.current && !isMoving) {
                objectPool.release(modelRef.current);
                isDisposed.current = true;
                return;
            }

            const scale = MODEL_SETTINGS.SCALE * (distance > LOD_DISTANCES.LOW ? 0.5 : 
                         distance > LOD_DISTANCES.MEDIUM ? 0.75 : 1);
            modelRef.current.scale.setScalar(scale);
        }
    });

    // 컴포넌트 마운트/언마운트 처리
    React.useEffect(() => {
        activeBoxes.add(index);

        return () => {
            activeBoxes.delete(index);
            
            if (modelRef.current && !isDisposed.current) {
                objectPool.release(modelRef.current);
                isDisposed.current = true;
            }
        };
    }, [index]);

    if (!model) return null;

    return (
        <group ref={ref} name={`box${index}`}>
            <primitive 
                ref={modelRef} 
                object={model} 
                rotation={[MODEL_SETTINGS.ROTATION.X, MODEL_SETTINGS.ROTATION.Y, MODEL_SETTINGS.ROTATION.Z]}
                position={[0, -MODEL_SETTINGS.BOUNDING_BOX.HEIGHT/2, 0]}
            />
            {/* 충돌체 시각화 - 디버깅용 
            <mesh
                rotation={[MODEL_SETTINGS.ROTATION.X, MODEL_SETTINGS.ROTATION.Y, MODEL_SETTINGS.ROTATION.Z]}
            >
                <boxGeometry args={[MODEL_SETTINGS.BOUNDING_BOX.WIDTH, MODEL_SETTINGS.BOUNDING_BOX.HEIGHT, MODEL_SETTINGS.BOUNDING_BOX.DEPTH]} />
                <meshBasicMaterial
                    color="#ff0000"
                    wireframe={true}
                    transparent={true}
                    opacity={0.3}
                />
            </mesh> */}
           
        </group>
    );
};

// 모델 프리로드 - 모든 디바이스에 최적화된 설정 적용
const modelsToPreload = MODEL_FILES.slice(0, 2);

modelsToPreload.forEach(file => {
    useGLTF.preload(`/models/${file}`);
});

// 컴포넌트 언마운트 시 cleanup 인터벌 정리
if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', () => {
        if (globalCleanupInterval) {
            clearInterval(globalCleanupInterval);
            globalCleanupInterval = null;
        }
    });
}

export default Box;