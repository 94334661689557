import startSound from '../assets/sounds/game-start.mp3';
import successSound from '../assets/sounds/box-success.mp3';
import gameOverSound from '../assets/sounds/game-over.mp3';

class SoundEffects {
    constructor() {
        this.startSound = new Audio(startSound);
        this.successSound = new Audio(successSound);
        this.gameOverSound = new Audio(gameOverSound);

        // 모든 효과음의 볼륨을 0.5로 설정
        this.startSound.volume = 0.7;
        this.successSound.volume = 0.7;
        this.gameOverSound.volume = 0.7;

        // 현재 재생 중인 오디오 목록
        this.activeAudios = new Set();

        // 페이지 가시성 변경 이벤트 리스너 등록
        if (typeof document !== 'undefined') {
            document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
        }
    }

    handleVisibilityChange() {
        if (document.hidden) {
            // 페이지가 숨겨질 때 모든 오디오 일시 정지
            this.activeAudios.forEach(audio => {
                if (!audio.paused) {
                    audio.pause();
                }
            });
        }
    }

    playAudio(audio) {
        this.activeAudios.add(audio);
        audio.currentTime = 0;
        audio.play()
            .catch(error => console.log('Audio play failed:', error))
            .finally(() => {
                // 재생이 끝나면 목록에서 제거
                audio.addEventListener('ended', () => {
                    this.activeAudios.delete(audio);
                }, { once: true });
            });
    }

    playStart() {
        this.playAudio(this.startSound);
    }

    playSuccess() {
        this.playAudio(this.successSound);
    }

    playGameOver() {
        this.playAudio(this.gameOverSound);
    }

    // 컴포넌트 언마운트 시 정리
    cleanup() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        }
        this.activeAudios.forEach(audio => {
            if (!audio.paused) {
                audio.pause();
            }
        });
        this.activeAudios.clear();
    }
}

export default new SoundEffects();
