import React from 'react';
import './Tutorial.css';
import SoundEffects from './components/SoundEffects';

const Tutorial = ({ onClose }) => {
  const handleStartGame = () => {
    SoundEffects.playStart();
    onClose();
  };

  return (
    <div className="tutorial-overlay">
      <div className="tutorial-content">
        <h2>게임 방법</h2>
        <div className="tutorial-steps">
          <div className="tutorial-step">
            <div className="tutorial-symbol box-symbol"></div>
            <p>떨어지는 크록스 제품을 쌓아보세요</p>
          </div>
          <div className="tutorial-step">
            <div className="tutorial-symbol touch-symbol"></div>
            <p>빈 곳을 터치해서 블록을 놓으세요</p>
          </div>
          <div className="tutorial-step">
            <div className="tutorial-symbol stack-symbol">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p>높이 쌓을수록 더 많은 점수를 얻을 수 있어요</p>
          </div>
        </div>
        <button className="start-button" onClick={handleStartGame}>
          게임 시작하기
        </button>
      </div>
    </div>
  );
};

export default Tutorial;
