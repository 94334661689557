class BoxSpawnQueue {
    constructor() {
        this.queue = [];
        this.isProcessing = false;
    }

    enqueue(spawnFunction) {
        this.queue.push(spawnFunction);
        this.processQueue();
    }

    async processQueue() {
        if (this.isProcessing || this.queue.length === 0) return;

        this.isProcessing = true;
        const spawnFunction = this.queue.shift();
        
        try {
            await spawnFunction();
        } catch (error) {
            console.error('Error processing box spawn:', error);
        }

        this.isProcessing = false;
        this.processQueue(); // Process next item if available
    }

    clear() {
        this.queue = [];
        this.isProcessing = false;
    }
}

export default new BoxSpawnQueue();
