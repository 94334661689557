import React, { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { CAMERA_SETTINGS } from '../gameConstants';
import * as THREE from 'three';

const CameraController = ({ boxCount, gamePhase }) => {
    const { camera } = useThree();
    const targetPositionRef = useRef(new THREE.Vector3(...CAMERA_SETTINGS.initialPosition));
    const currentVelocity = useRef(new THREE.Vector3(0, 0, 0));

    // 목표 카메라 위치 계산
    useEffect(() => {
        const targetY = CAMERA_SETTINGS.initialPosition[1] + (boxCount * CAMERA_SETTINGS.heightOffset);
        targetPositionRef.current.set(
            CAMERA_SETTINGS.initialPosition[0],
            targetY,
            CAMERA_SETTINGS.initialPosition[2]
        );
    }, [boxCount]);

    // 부드러운 카메라 이동
    useFrame(() => {
        const deltaTime = 0.016; // 약 60fps
        const springStrength = 4.0;
        const damping = 0.8;

        // 현재 위치와 목표 위치의 차이 계산
        const positionDelta = targetPositionRef.current.clone().sub(camera.position);
        
        // 스프링 힘 계산
        const springForce = positionDelta.multiplyScalar(springStrength);
        
        // 속도 업데이트 (가속도 적용)
        currentVelocity.current.add(springForce.multiplyScalar(deltaTime));
        
        // 감쇠 적용
        currentVelocity.current.multiplyScalar(1 - damping * deltaTime);
        
        // 위치 업데이트
        camera.position.add(currentVelocity.current.multiplyScalar(deltaTime));

        // 항상 중앙을 바라보도록 설정 (더 완만한 각도)
        camera.lookAt(0, camera.position.y * 0.7, 0);
    });

    // 게임 리셋 시 카메라 초기화
    useEffect(() => {
        if (gamePhase === 'initial') {
            camera.position.set(...CAMERA_SETTINGS.initialPosition);
            currentVelocity.current.set(0, 0, 0);
            camera.lookAt(0, camera.position.y * 0.5, 0);
        }
    }, [gamePhase, camera]);

    return null;
};

export default CameraController;
