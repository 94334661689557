import React, { useRef, useEffect } from 'react';
import { useBox } from '@react-three/cannon';
import * as THREE from 'three';

function Plane() {
    const planeArgs = [12, 4, 12];  // 변수로 분리하여 재사용

    const [ref] = useBox(() => ({
        args: planeArgs,  // 변수 사용
        position: [0, -1, 0],
        type: 'Static',
    }));   

    return (
        <>
            {/* 실제 플레인 */}
            <mesh
                ref={ref}
                name="plane"
                receiveShadow
                castShadow
            >
                <boxGeometry args={planeArgs} />
                <meshStandardMaterial
                    color="#ee9ac2"
                    roughness={0.8}
                    metalness={0.3}
                    reflectivity={0.8}
                    clearcoat={1}
                    clearcoatRoughness={0}
                    envMapIntensity={0.5}
                    emissive="#000000"
                    emissiveIntensity={0}
                    toneMapped={true}                   
                />
            </mesh>
            
            {/* 충돌체 시각화 
            <mesh position={[0, -2, 0]}>
                <boxGeometry args={planeArgs} />
                <meshBasicMaterial
                    color="#00ff00"
                    wireframe={true}
                    transparent={true}
                    opacity={0.3}
                />
            </mesh> */}
        </>
    );
}

export default Plane;