import React, { useState, useEffect, useRef } from 'react';

const BGM_TRACKS = [
  '/music/G-Yerro-ChristmasIsComing.mp3',
  '/music/Christmas-Wonderland.mp3'
];

const AudioControls = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const currentTrackIndex = useRef(Math.floor(Math.random() * BGM_TRACKS.length));
  const hasInteracted = useRef(false);

  const switchToNextTrack = () => {
    if (!audioRef.current) return;
    
    const wasMuted = audioRef.current.muted;
    
    currentTrackIndex.current = (currentTrackIndex.current + 1) % BGM_TRACKS.length;
    audioRef.current.src = BGM_TRACKS[currentTrackIndex.current];
    audioRef.current.muted = wasMuted;
    audioRef.current.volume = 0.30; // 볼륨을 35%로 설정
    
    if (isPlaying) {
      audioRef.current.play().catch(error => {
        console.log('Playback was prevented:', error);
        setIsPlaying(false);
      });
    }
  };

  const startPlayback = () => {
    if (!hasInteracted.current && audioRef.current && isPlaying) {
      hasInteracted.current = true;
      audioRef.current.play().catch(error => {
        console.log('Playback was prevented:', error);
        setIsPlaying(false);
      });
    }
  };

  const togglePlayback = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(BGM_TRACKS[currentTrackIndex.current]);
      audioRef.current.loop = true;
      audioRef.current.volume = 0.30;
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.error('Audio playback failed:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden || document.visibilityState === 'hidden') {
        if (audioRef.current && !audioRef.current.paused) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      } else {
        // 페이지가 다시 보일 때
        if (audioRef.current && isPlaying) {
          audioRef.current.play().catch(error => {
            console.error('Audio playback failed:', error);
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current = new Audio(BGM_TRACKS[currentTrackIndex.current]);
    audioRef.current.loop = true;
    audioRef.current.volume = 0.30;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const handleInteraction = () => {
      startPlayback();
    };

    const handleVisibilityChange = () => {
      if (!document.hidden && hasInteracted.current && isPlaying && audioRef.current) {
        audioRef.current.play().catch(error => {
          console.log('Playback was prevented:', error);
          setIsPlaying(false);
        });
      }
    };

    // 사용자 인터랙션 이벤트 리스너 추가
    const events = ['touchstart', 'mousedown', 'keydown'];
    events.forEach(event => {
      window.addEventListener(event, handleInteraction, { once: true });
    });

    // 페이지 가시성 변경 감지
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // 클린업 시 이벤트 리스너 제거
      events.forEach(event => {
        window.removeEventListener(event, handleInteraction);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isPlaying]);

  const toggleMute = () => {
    if (!audioRef.current) return;
    audioRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const switchTrack = () => {
    if (!audioRef.current) return;
    
    const wasPlaying = !audioRef.current.paused;
    const wasMuted = audioRef.current.muted;

    currentTrackIndex.current = (currentTrackIndex.current + 1) % BGM_TRACKS.length;
    audioRef.current.src = BGM_TRACKS[currentTrackIndex.current];
    audioRef.current.muted = wasMuted;

    if (wasPlaying) {
      audioRef.current.play().catch(error => {
        console.log('Playback was prevented:', error);
        setIsPlaying(false);
      });
    }
  };

  return (
    <div className="audio-controls">
      <button onClick={togglePlayback} className="audio-button">
        {isPlaying ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <rect x="6" y="4" width="4" height="16"></rect>
            <rect x="14" y="4" width="4" height="16"></rect>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <polygon points="5 3 19 12 5 21 5 3"></polygon>
          </svg>
        )}
      </button>
      <button onClick={toggleMute} className="audio-button">
        {isMuted ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M11 5L6 9H2v6h4l5 4V5z"></path>
            <line x1="23" y1="9" x2="17" y2="15"></line>
            <line x1="17" y1="9" x2="23" y2="15"></line>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
            <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
          </svg>
        )}
      </button>
      <button onClick={switchTrack} className="audio-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M17 1l4 4-4 4"></path>
          <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
          <path d="M7 23l-4-4 4-4"></path>
          <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
        </svg>
      </button>
    </div>
  );
};

export default AudioControls;
