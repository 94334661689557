import React from 'react';
import { FaShare } from 'react-icons/fa';
import html2canvas from 'html2canvas';  // 주석 처리하지 않고 남겨둠 (나중에 필요할 수 있음)

const ShareButton = () => {
    const handleShare = async () => {
        let score;  // score 변수를 상단에서 선언
        try {
            score = document.querySelector('.score-display')?.textContent || '0';  // score 할당

            /* 스크린샷 캡쳐 기능 주석 처리
            const gameSection = document.querySelector('.game-section');
            const score = document.querySelector('.score-display')?.textContent || '0';

            // 캡쳐 전 스타일 저장
            const gameOverOverlay = gameSection.querySelector('.game-over-overlay');
            const tutorialButton = gameSection.querySelector('.tutorial-button');
            let originalStyles = null;
            let tutorialStyles = null;
            
            if (gameOverOverlay) {
                const children = gameOverOverlay.children;
                originalStyles = Array.from(children).map(child => ({
                    element: child,
                    style: { ...child.style },
                    computedStyle: window.getComputedStyle(child)
                }));

                Array.from(children).forEach(child => {
                    const computedStyle = window.getComputedStyle(child);
                    child.style.position = 'relative';
                    child.style.top = '0';
                    child.style.transform = 'none';
                    child.style.margin = computedStyle.margin;
                    child.style.padding = computedStyle.padding;
                    child.style.lineHeight = computedStyle.lineHeight;
                    child.style.display = 'block';
                    child.style.textAlign = 'center';
                });
            }

            if (tutorialButton) {
                const children = tutorialButton.children;
                tutorialStyles = Array.from(children).map(child => ({
                    element: child,
                    style: { ...child.style },
                    computedStyle: window.getComputedStyle(child)
                }));

                Array.from(children).forEach(child => {
                    const computedStyle = window.getComputedStyle(child);
                    child.style.position = 'relative';
                    child.style.top = '0';
                    child.style.transform = 'none';
                    child.style.margin = computedStyle.margin;
                    child.style.padding = computedStyle.padding;
                    child.style.lineHeight = computedStyle.lineHeight;
                    child.style.display = 'block';
                    child.style.textAlign = 'center';
                });
            }

            const canvas = await html2canvas(gameSection, {
                backgroundColor: '#1a1a1a',
                scale: window.devicePixelRatio || 2,
                useCORS: true,
                allowTaint: true,
                onclone: (clonedDoc, clonedElement) => {
                    const elements = clonedElement.querySelectorAll('*');
                    elements.forEach(el => {
                        if (window.getComputedStyle(el).display === 'none') {
                            el.style.display = 'block';
                        }
                        el.style.opacity = '1';
                        el.style.visibility = 'visible';
                    });

                    const clonedTutorialButton = clonedElement.querySelector('.tutorial-button');
                    if (clonedTutorialButton) {
                        const originalButton = gameSection.querySelector('.tutorial-button');
                        const originalRect = originalButton.getBoundingClientRect();
                        const gameSectionRect = gameSection.getBoundingClientRect();

                        const questionIcon = clonedTutorialButton.querySelector('.question-icon, span');
                        if (questionIcon) {
                            const originalIcon = originalButton.querySelector('.question-icon, span');
                            const originalIconRect = originalIcon.getBoundingClientRect();
                            const buttonRect = originalButton.getBoundingClientRect();
                            
                            const iconTop = originalIconRect.top - buttonRect.top;
                            
                            questionIcon.style.position = 'absolute';
                            questionIcon.style.top = `${iconTop}px`;
                            questionIcon.style.left = '50%';
                            questionIcon.style.transform = 'translateX(-50%)';
                            questionIcon.style.margin = '0';
                            questionIcon.style.padding = '0';
                            questionIcon.style.height = 'auto';
                            questionIcon.style.lineHeight = '1';
                        }
                    }

                    const tutorialButton = clonedElement.querySelector('.tutorial-button');
                    if (tutorialButton) {
                        const originalButton = gameSection.querySelector('.tutorial-button');
                        const originalRect = originalButton.getBoundingClientRect();
                        const gameSectionRect = gameSection.getBoundingClientRect();

                        const relativeTop = originalRect.top - gameSectionRect.top;
                        const relativeLeft = originalRect.left - gameSectionRect.left;

                        tutorialButton.style.position = 'absolute';
                        tutorialButton.style.top = `${relativeTop}px`;
                        tutorialButton.style.left = `${relativeLeft}px`;
                        tutorialButton.style.width = `${originalRect.width}px`;
                        tutorialButton.style.height = `${originalRect.height}px`;
                        
                        const children = tutorialButton.children;
                        Array.from(children).forEach(child => {
                            child.style.position = 'relative';
                            child.style.top = '0';
                            child.style.left = '0';
                            child.style.transform = 'none';
                            child.style.margin = '0';
                            child.style.display = 'flex';
                            child.style.alignItems = 'center';
                            child.style.justifyContent = 'center';
                            child.style.width = '100%';
                            child.style.height = '100%';
                        });
                    }

                    const clonedOverlay = clonedElement.querySelector('.game-over-overlay');
                    if (clonedOverlay) {
                        const originalOverlay = gameSection.querySelector('.game-over-overlay');
                        const originalRect = originalOverlay.getBoundingClientRect();
                        const gameSectionRect = gameSection.getBoundingClientRect();

                        const relativeTop = originalRect.top - gameSectionRect.top;
                        const relativeLeft = originalRect.left - gameSectionRect.left;

                        clonedOverlay.style.position = 'absolute';
                        clonedOverlay.style.top = `${relativeTop}px`;
                        clonedOverlay.style.left = `${relativeLeft}px`;
                        clonedOverlay.style.width = `${originalRect.width}px`;
                        clonedOverlay.style.height = `${originalRect.height}px`;
                        clonedOverlay.style.transform = 'none';
                        clonedOverlay.style.display = 'flex';
                        clonedOverlay.style.alignItems = 'center';
                        clonedOverlay.style.justifyContent = 'center';
                    }
                }
            });

            canvas.toBlob(async (blob) => {
                try {
                    const file = new File([blob], 'game-screenshot.png', { type: 'image/png' });
                    const shareData = {
                        title: '크록스 홀리데이 게임',
                        text: `내 점수: ${score}점! 당신도 도전해보세요!`,
                        url: 'https://crocsholiday2024.com',
                        files: [file]
                    };

                    await navigator.share(shareData);
                } catch (shareError) {
                    console.log('Falling back to URL-only share');
                    await navigator.share({
                        title: shareData.title,
                        text: shareData.text,
                        url: shareData.url
                    });
                }

                // 원래 스타일 복구
                if (gameOverOverlay && originalStyles) {
                    originalStyles.forEach(({element, style}) => {
                        Object.keys(style).forEach(key => {
                            element.style[key] = style[key];
                        });
                    });
                }

                if (tutorialButton && tutorialStyles) {
                    tutorialStyles.forEach(({element, style}) => {
                        Object.keys(style).forEach(key => {
                            element.style[key] = style[key];
                        });
                    });
                }
            }, 'image/png');
            */

            // 새로운 공유 기능 구현
            const shareData = {
                title: '크록스 홀리데이 2024 게임',
                text: `내 점수: ${score}점! 크록스와 함께하는 신나는 블록 쌓기 게임에 도전해보세요! 🎮✨`,
                url: `https://crocsholiday2024.com?utm_source=share&utm_medium=social&utm_campaign=game&score=${score}`
            };

            // Web Share API를 지원하는 경우 (모바일)
            if (navigator.share) {
                await navigator.share(shareData);
            } 
            // Web Share API를 지원하지 않는 경우 (데스크톱)
            else {
                // 클립보드에 공유 텍스트 복사
                const shareText = `${shareData.title}\n${shareData.text}\n${shareData.url}`;
                await navigator.clipboard.writeText(shareText);
                alert('공유 링크가 클립보드에 복사되었습니다!');
            }
        } catch (error) {
            console.error('Share failed:', error);
            // 공유 실패 시 클립보드 복사 시도
            try {
                const shareText = `크록스 홀리데이 2024 게임\n내 점수: ${score}점! 크록스와 함께하는 신나는 블록 쌓기 게임에 도전해보세요! 🎮✨\nhttps://crocsholiday2024.com`;
                await navigator.clipboard.writeText(shareText);
                alert('공유 링크가 클립보드에 복사되었습니다!');
            } catch (clipboardError) {
                alert('공유하기에 실패했습니다. 다시 시도해주세요.');
            }
        }
    };

    return (
        <button onClick={handleShare} className="share-button">
            <FaShare />
        </button>
    );
};

export default ShareButton;