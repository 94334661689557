import React from 'react';
import './TutorialButton.css';

const TutorialButton = ({ onClick }) => {
    return (
        <button className="tutorial-button" onClick={onClick}>
            <span>?</span>
        </button>
    );
};

export default TutorialButton;
